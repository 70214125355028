import React, { FC } from 'react';
import { useSettings } from '@wix/tpa-settings/react';
import { useEnvironment, WidgetProps } from '@wix/yoshi-flow-editor';
import { SettingsAdapterProvider, StylesParamsProvider } from '../../../hooks';
import { PlanListInteractions } from '../../../types/PlanListFedops';
import CssVars from '../../PackagePicker/CssVars';
import List, { ListWidgetProps } from '../../PackagePicker/Widget/List';
import { EmptyStateProps } from '../../PackagePicker/Widget/List/EmptyState';
import { PlanListSettingsAdapter } from '../PlanListSettingsAdapter';
import settingsParams from '../settingsParams';
import stylesParams from '../stylesParams';

const Widget: FC<WidgetProps<ListWidgetProps>> = (props) => {
  const settings = useSettings();
  const settingsAdapter = React.useMemo(() => new PlanListSettingsAdapter(settings, settingsParams), [settings]);
  const { isRTL } = useEnvironment();
  const emptyState: EmptyStateProps = { isInWidget: true };
  return (
    <StylesParamsProvider stylesParams={stylesParams}>
      <CssVars id={props.host.id} style={props.host.style} />
      <SettingsAdapterProvider adapter={settingsAdapter}>
        <div dir={isRTL ? 'rtl' : 'ltr'}>
          <List
            {...props}
            dataHook="PlanList-wrapper"
            interactionEnd={PlanListInteractions.WidgetLoaded}
            emptyState={emptyState}
          />
        </div>
      </SettingsAdapterProvider>
    </StylesParamsProvider>
  );
};

export default Widget;
